import React, { useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Offer1 from "../Media/offer1.png";
import Offer2 from "../Media/offer2.png";
import Offer3 from "../Media/offer3.png";
import Offer4 from "../Media/offer4.png";
import Offer5 from "../Media/offer5.png";
import Offer6 from "../Media/offer6.png";
import "../Styles/Offers2.css";

const Offer = () => {
  useEffect(() => {
    document.getElementsByClassName("offer-slide")[1].id = "selected-element";
  });

  return (
    <center>
      <div id="Features" className="offer-container">
        {/* <img className="bg-left" src={BG_dot} alt="Background" />
        <img className="bg-right" src={BG_dot} alt="Background" /> */}
        <div className="offers-heading">WHAT WE OFFER</div>
        <div className="offer-screen">
          <img
            id="current-offer-screen-bg"
            src={Offer1}
            alt="Design of websites and apps"
            className="offer-bg"
          />
          <img
            id="later-offer-screen-bg"
            src={Offer2}
            alt="Design of websites and apps"
            className="offer-bg"
          />
          <img
            id="later-offer-screen-bg"
            src={Offer3}
            alt="Design of websites and apps"
            className="offer-bg"
          />
          <img
            id="later-offer-screen-bg"
            src={Offer4}
            alt="Design of websites and apps"
            className="offer-bg"
          />
          <img
            id="later-offer-screen-bg"
            src={Offer5}
            alt="Design of websites and apps"
            className="offer-bg"
          />
          <img
            id="later-offer-screen-bg"
            src={Offer6}
            alt="Design of websites and apps"
            className="offer-bg"
          />

          <Carousel
            className="offer-carousel"
            axis="horizontal"
            centerMode={true}
            centerSlidePercentage={100}
            infiniteLoop={true}
            autoPlay={true}
            interval={5000}
            showStatus={false}
            showIndicators={false}
            showArrows={false}
            useKeyboardArrows={true}
            showThumbs={false}
            transitionTime={1000}
            onChange={(selectedIndex) => {
              let i;
              let index;
              index = selectedIndex + 1;
              for (i = 0; i <= 6; i++) {
                document.getElementsByClassName("offer-slide")[i].id =
                  "not-selected-element";
              }
              for (i = 0; i < 6; i++) {
                document.getElementsByClassName("offer-bg")[i].id =
                  "later-offer-screen-bg";
              }
              document.getElementsByClassName("offer-slide")[index].id =
                "selected-element";
              document.getElementsByClassName("offer-bg")[selectedIndex].id =
                "current-offer-screen-bg";
            }}
          >
            <div className="offer-slide" id="not-selected-element">
              <div className="offer-element">
                <div className="offer-element-head">
                  Design of Websites & Apps
                </div>
                <div className="offer-element-content">
                  Impress your viewers with aesthetically beautiful apps and
                  webpages. Our designs create appealing digital interfaces that
                  have a lasting impression by effectively combining
                  functionality and aesthetics.
                </div>
              </div>
            </div>
            <div className="offer-slide" id="not-selected-element">
              <div className="offer-element">
                <div className="offer-element-head">Portfolio Development</div>
                <div className="offer-element-content">
                  Stand out professionally with our customised portfolio
                  designs, which are made to showcase your unique talents and
                  make a lasting impression.
                </div>
              </div>
            </div>
            <div className="offer-slide" id="not-selected-element">
              <div className="offer-element">
                <div className="offer-element-head">
                  Business and Product Websites
                </div>
                <div className="offer-element-content">
                  With our custom websites for businesses and products, ignite
                  online success. We create captivating digital experiences that
                  captivate users and propel business growth.
                </div>
              </div>
            </div>
            <div className="offer-slide" id="not-selected-element">
              <div className="offer-element">
                <div className="offer-element-head">E-Commerce Sites</div>
                <div className="offer-element-content">
                  With the help of our robust e-commerce platforms, unleash the
                  potential of online sales. We design smooth, intuitive
                  platforms that draw users in and ensures a delightful and safe
                  buying experience.
                </div>
              </div>
            </div>
            <div className="offer-slide" id="not-selected-element">
              <div className="offer-element">
                <div className="offer-element-head">Domain Hosting</div>
                <div className="offer-element-content">
                  Our robust domain hosting services make it easy to build a
                  strong online presence. You can rely on us to offer the
                  framework for a compelling, dependable, and easily navigable
                  website.
                </div>
              </div>
            </div>
            <div className="offer-slide" id="not-selected-element">
              <div className="offer-element">
                <div className="offer-element-head">
                  Application Development
                </div>
                <div className="offer-element-content">
                  With the help of our creative app development services,
                  implement your ideas. We are experts at creating user-friendly
                  mobile applications that fascinate and involve a diverse range
                  of clients, from conception to implementation.
                </div>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </center>
  );
};

export default Offer;
