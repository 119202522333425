import { ContactShadows } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import React, { Suspense } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../App.css";
import Model from "../Assets/Mitbot";
import Logo from "../Media/Logo-Transbg.png";

const Home = () => {
  // const h = window.innerHeight;
  // let index = 1;
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     // let cid = caption_words[index];
  //     // document.getElementById(cid).className = "past-caption-word";
  //     var val;
  //     if (window.innerWidth < 600) {
  //       val = index * 22;
  //     } else {
  //       val = index * 52;
  //     }
  //     index++;
  //     document.getElementById("scroll-caption").style.transform =
  //       "translateY(-" + val + "px)";
  //     document.getElementById("scroll-caption").style.transition = "1s";
  //     if (index > 4) {
  //       index = 0;
  //     }
  //     // cid = caption_words[index];
  //     // document.getElementById(cid).className = "current-caption-word";
  //   }, 3600);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [h]);

  return (
    <>
      <div className="home-container">
        <header className="logo-holder">
          <img src={Logo} alt="Mitbots Logo" className="logo" />
        </header>
        <div className="home-body">
          <div className="title-layer-1" style={{ position: "absolute" }}>
            MITBOTS
          </div>
          <div
            style={{
              width: "100vw",
              height: "80vh",
              position: "absolute",
            }}
            id="main"
          >
            <Canvas shadows={0.5}>
              <ambientLight intensity={2.4} />
              <directionalLight position={[0, 10, 5]} intensity={1} />
              <Suspense fallback={null}>
                <Model />
              </Suspense>
              <ContactShadows
                position={[0, -2, 0]}
                opacity={0.5}
                scale={12}
                blur={1.2}
                far={12}
                resolution={256}
                color={"black"}
              />
            </Canvas>
          </div>

          <div className="title-layer-2">MITBOTS</div>
          <div className="home-caption">
            Embrace Brilliance of
            <span id="caption-word-change">
              <span id="scroll-caption" className="home-caption-word">
                <span className="current-caption-word" id="Technology">
                  Technology
                </span>
                {/* <span className="past-caption-word" id="Digitalization">
                  Digitalization
                </span>
                <span className="past-caption-word" id="Design">
                  Design
                </span>
                <span className="past-caption-word" id="Screens">
                  Screens
                </span>
                <span className="past-caption-word" id="Creativity">
                  Creativity
                </span> */}
              </span>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
