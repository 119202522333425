import React from "react";
import { FaCheck } from "react-icons/fa6";
import "../Styles/Choose.css";

//let sh = window.innerHeight - 50;

const Choose = () => {
  return (
    <div id="Package" className="Package-container">
      <div className="why-choose-us">
        <div className="why-head-box">
          <div className="why-head">
            Why Ch<span className="highlight">oo</span>se Us?
          </div>
        </div>
        <div className="why-sections">
          <div className="why-section1">
            <div className="why-point">
              Ethical & Legal Business Practices{" "}
              <FaCheck className="why-check" />
              <div className="why-point-content">
                We adhere to all the legal practices and requirements throughout
                the development practices without any negotiation.
              </div>
            </div>
            <div className="why-point">
              Design Mastery <FaCheck className="why-check" />
              <div className="why-point-content">
                We ensure to provide a seamless and visually appealing
                experience across all devices.
              </div>
            </div>
            <div className="why-point">
              Long-term Vision <FaCheck className="why-check" />
              <div className="why-point-content">
                Our vision extends beyond individual project to provide
                post-launch support and build lasting relationships.
              </div>
            </div>
            <div className="why-point">
              Passionate <FaCheck className="why-check" />
              <div className="why-point-content">
                As a fresher we are passionate and eager to make a lasting
                impression, ensuring your project receives our utmost
                dedication.
              </div>
            </div>
            <div className="why-point">
              Adaptability <FaCheck className="why-check" />
              <div className="why-point-content">
                Our flexibility enables us to quickly adjust to the demands of
                your project, guaranteeing a responsive and dynamic development
                process.
              </div>
            </div>
            <div className="why-point">
              Detail Oriented <FaCheck className="why-check" />
              <div className="why-point-content">
                We create a polished and professional final product for your
                site, paying close attention to every pixel and line of code.
              </div>
            </div>
          </div>
          <div className="why-separator"></div>
          <div className="why-section2">
            <div className="why-point">
              <FaCheck className="why-check" />
              Commitment to Deadlines
              <div className="why-point-content">
                We are committed to meeting deadlines. Your project will be
                delivered on time, without compromising on quality.
              </div>
            </div>
            <div className="why-point">
              <FaCheck className="why-check" />
              Transparent Collaboration
              <div className="why-point-content">
                We provide a clear and transparent communication experience,
                keeping you updated and involved in every step of the way.
              </div>
            </div>
            <div className="why-point">
              <FaCheck className="why-check" />
              Personalised Service
              <div className="why-point-content">
                Enjoy a level of personalised service where we give your project
                the attention it deserves.
              </div>
            </div>
            <div className="why-point">
              <FaCheck className="why-check" />
              Innovative Perspective{" "}
              <div className="why-point-content">
                As a novice, we approach your project with fresh perspectives
                and creative ideas, steering clear of the rut of tried-and-true
                fixes.
              </div>
            </div>
            <div className="why-point">
              <FaCheck className="why-check" />
              Tech-Savvy
              <div className="why-point-content">
                We stay ahead with the latest technologies by incorporating
                cutting-edge tools and trends into your website.
              </div>
            </div>
            <div className="why-point">
              <FaCheck className="why-check" />
              Affordable Excellence{" "}
              <div className="why-point-content">
                Enjoy the advantages of high-quality work at a significantly
                reduced cost, providing excellent service while staying within
                your price range.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Choose;
