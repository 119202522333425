import React from "react";
import "../Styles/Way.css";

const Way = () => {
  // function rotate_toy(e) {
  //   var ast = document.getElementById("astronaut");
  //   var x = e.clientX - window.innerWidth / 2;
  //   var y = e.clientY - window.innerHeight / 2;
  //   var q = 0.15;

  //   x = x * q * 1.25;
  //   y = -y * q * 1.25;

  //   ast.style.transform = "rotateY(" + x + "deg) rotateX(" + y + "deg)";
  // }

  // document.addEventListener("mousemove", rotate_toy);

  // useEffect(() => {
  //   document.getElementsByClassName("way-toy")[0].id = "toy-disp";
  // });

  return (
    <div id="Contact" className="Way-container">
      <div className="way-head">
        <span className="highlight">How to place </span>an order?
      </div>
      <div id="section-container" className="steps-sections">
        <div className="step-section step-sec1">
          <div className="step1 way-step">
            <div className="step-head">1. Initiate Contact</div>
            <div className="step-body">
              Embark on a seamless journey by reaching out through our 'Contact
              Us' portal. Let's kickstart a conversation about your vision and
              aspirations.
            </div>
          </div>
          <hr className="steps-separator" />
          <div className="step2 way-step">
            <div className="step-head">2. Project Specification</div>
            <div className="step-body">
              Explain every aspect of your project to understand it precisely.
              Give thorough information that will act as the basis for an expert
              and customised solution.
            </div>
          </div>
          <hr className="steps-separator" />
          <div className="step3 way-step">
            <div className="step-head">3. Contractual Accord</div>
            <div className="step-body">
              We'll send you the terms and conditions for your approval as soon
              as we fully comprehend your project. Once we're in agreement,
              we'll formally begin working together by signing a contract that
              contains information about the project, deadlines, and payment.
            </div>
          </div>
          <hr className="additional steps-separator" />
        </div>
        <div className="step-section step-sec2">
          <div
            className="step4 way-step"
            // onMouseEnter={() => {
            //   var e = document.getElementsByClassName("way-toy");
            //   let i;
            //   for (i = 0; i < e.length; i++) {
            //     e[i].id = "no-toy-disp";
            //   }
            //   e[4].id = "toy-disp";
            // }}
            // onMouseLeave={() => {
            //   document.getElementById("toy-disp").id = "no-toy-disp";
            //   document.getElementsByClassName("way-toy")[0].id = "toy-disp";
            // }}
          >
            <div className="step-head text-left">4. Prep for Success</div>
            <div className="step-body">
              Kindly provide any prerequisites or specific supplies needed at
              this point if they apply to the project. This guarantees a smooth
              and timely start to the work.
            </div>
          </div>
          <hr className="steps-separator" />
          <div className="step5 way-step">
            <div className="step-head text-left">
              5. Product Delivery and Transaction
            </div>
            <div className="step-body">
              Witness your vision come to life when we present the finished
              product. The characteristics of our transaction are punctuality,
              quality control, and agreement to the terms set forth. It's a
              triumphant moment when satisfaction meets quality.
            </div>
          </div>
          <hr className="steps-separator" />
          <div className="step6 way-step">
            <div className="step-head text-left">6. Feedback Elevation</div>
            <div className="step-body">
              We intend to enhance our collaboration with your insightful
              feedback. Give us your thoughts on how we can improve our
              offerings and support a never-ending cycle of client satisfaction
              and improvement.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Way;
