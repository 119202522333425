import firebase from "firebase/compat/app";
import "firebase/compat/database";

const firebaseConfig = {
  apiKey: "AIzaSyBgU-dWufdnspafFaZHf0xLXWe83eESo6g",
  authDomain: "mitbots-site-collected-data.firebaseapp.com",
  databaseURL:
    "https://mitbots-site-collected-data-default-rtdb.firebaseio.com",
  projectId: "mitbots-site-collected-data",
  storageBucket: "mitbots-site-collected-data.appspot.com",
  messagingSenderId: "889210285293",
  appId: "1:889210285293:web:240bc74cfd9a3b37d2b0e4",
  measurementId: "G-27BD1DR43D",
};

firebase.initializeApp(firebaseConfig);
var db = firebase.database();
export default db;
