import React, { useEffect } from "react";
import "../Styles/Cursor.css";

const Cursor = () => {
  useEffect(() => {
    const coords = { x: 0, y: 0 };
    const circles = document.querySelectorAll(".circle");
    const colors = [
      // "#ffb56b",
      // "#fdaf69",
      // "#f89d63",
      // "#f59761",
      // "#ef865e",
      // "#ec805d",
      // "#e36e5c",
      // "#df685c",
      // "#d5585c",
      // "#d1525c",
      // "#c5415d",
      // "#c03b5d",
      // "#b22c5e",
      // "#ac265e",
      // "#9c155f",
      // "#950f5f",
      // "#830060",
      // "#7c0060",
      // "#680060",
      // "#60005f",
      // "#48005f",
      // "#3d005e",
      "#ff9999",
      "#ff8080",
      "#ff6666",
      "#ff4d4d",
      "#ff3333",
      "#ff1a1a",
      "#ff0000",
      "#f20000",
      "#e60000",
      "#d90000",
      "#cc0000",
      "#bf0000",
      "#b30000",
      "#a60000",
      "#990000",
      "#8c0000",
      "#800000",
      "#730000",
      "#660000",
      "#590000",
      "#4d0000",
      "#400000",
      "#330000",
    ];

    circles.forEach(function (circle, index) {
      circle.x = 0;
      circle.y = 0;
      circle.style.backgroundColor = colors[index % colors.length];
    });

    window.addEventListener("mousemove", function (e) {
      coords.x = e.clientX;
      coords.y = e.clientY;
    });

    function animateCircles() {
      let x = coords.x;
      let y = coords.y;

      circles.forEach(function (circle, index) {
        circle.style.left = x - 12 + "px";
        circle.style.top = y - 12 + "px";

        circle.style.scale = (circles.length - index) / circles.length;

        circle.x = x;
        circle.y = y;

        const nextCircle = circles[index + 1] || circles[0];
        x += (nextCircle.x - x) * 0.6;
        y += (nextCircle.y - y) * 0.6;
      });

      requestAnimationFrame(animateCircles);
    }

    animateCircles();
  }, []);

  return (
    <>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
    </>
  );
};

export default Cursor;
