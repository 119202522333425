import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../Styles/Founders.css";

const Founders = () => {
  return (
    <>
      <div className="Founders-container">
        <div className="founders-heading">The Team</div>
        <div className="founders-box">
          <Carousel
            className="founder-carousel"
            axis="horizontal"
            centerMode={true}
            centerSlidePercentage={100}
            infiniteLoop={true}
            autoPlay={false}
            showStatus={false}
            showIndicators={true}
            showArrows={true}
            useKeyboardArrows={true}
            showThumbs={false}
            transitionTime={1000}
            onChange={(selectedIndex) => {
              let i;
              let index;
              index = selectedIndex + 1;
              for (i = 0; i <= 1; i++) {
                document.getElementsByClassName("team-member-slide")[i].id =
                  "not-selected-team-member";
              }
              document.getElementsByClassName("team-member-slide")[index].id =
                "selected-team-member";
            }}
          >
            <div className="team-member-slide" id="selected-team-member">
              <div className="team-member-pic" id="ceo"></div>
              <div className="team-member-details">
                <div className="team-member-name">Prithiyanga</div>
                <div className="team-member-position">Founder & CEO</div>
                <div className="team-member-about">
                  Prithiyanga, the Founder and CEO of Mitbots, is a skilled
                  full-stack developer who began her journey in January 2024.
                  With a strong passion for technology, she leads the team with
                  creativity and determination. Prithiyanga fosters a supportive
                  environment that inspires innovation and teamwork, ensuring
                  the company consistently exceeds client expectations and
                  thrives.
                </div>
              </div>
            </div>
            <div className="team-member-slide" id="not-selected-team-member">
              <div className="team-member-pic" id="coo"></div>
              <div className="team-member-details">
                <div className="team-member-name">Vishal M</div>
                <div className="team-member-position">COO & Design Lead</div>
                <div className="team-member-about">
                  Vishal M, our dynamic COO and Design Lead, joined Mitbots in
                  January 2024. He has a BE in Automobile Engineering and brings
                  experience in creating innovative designs. He combines
                  function and creativity in our projects. As a team leader, he
                  values everyone’s input, making sure our designs meet users'
                  needs.
                </div>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default Founders;
