import React, { useState } from "react";
import { AiFillInstagram } from "react-icons/ai";
import { FaLinkedinIn, FaPhoneAlt } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { MdLocationPin } from "react-icons/md";
import db from "../Backend/firebase.jsx";
import closeicon from "../Media/closethankyou.png";
import Logo from "../Media/Footer_Logo.png";
import scimg from "../Media/thankyou.png";
import "../Styles/Footer.css";

const Footer = () => {
  const [details, setDetails] = useState({
    name: "",
    mail: "",
    phone: 0,
    message: "",
  });

  var scrollLeft, scrollTop;

  const disableScroll = () => {
    window.scrollTo(scrollLeft, scrollTop);
  };

  const enableScroll = () => {
    document.getElementById("smb").style.display = "none";
    window.removeEventListener("scroll", disableScroll);
  };

  function SendData(e) {
    e.preventDefault();

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    var name = document.getElementById("name-input").value;
    var mail = document.getElementById("mail-input").value;
    var phone = document.getElementById("phone-input").value;
    var message = document.getElementById("msg-input").value;

    if (name === "" || mail === "" || phone === 0 || message === "") {
      alert("Kindly fill all the fields");
      return;
    } else if (!emailRegex.test(mail)) {
      alert("Kindly check your email");
    } else {
      db.ref(name)
        .set({
          name: name,
          mail: mail,
          phone: phone,
          message: message,
        })
        .then(() => {
          scrollTop = window.pageYOffset || document.documentElement.scrollTop;
          scrollLeft =
            window.pageXOffset || document.documentElement.scrollLeft;
          document.getElementById("smb").style.display = "flex";
          if (window.innerWidth > 1024) {
            setTimeout(() => {
              document.getElementById("smb").style.display = "none";
            }, 1000);
          } else {
            window.addEventListener("scroll", disableScroll);
          }
          document.getElementById("contactForm").reset();
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });

      name = "";
      mail = "";
      message = "";
      phone = 0;
    }
  }

  return (
    <div id="Footer" className="Footer-container">
      <div className="footer-section">
        <div className="footer-logo-div">
          <img className="footer-logo" alt="Mitbots Logo" src={Logo} />
        </div>
        <div className="contact-div">
          <form id="contactForm" className="contact-details">
            <div className="contact-det-section">
              <input
                className="detail-input"
                type="text"
                placeholder="NAME"
                id="name-input"
                onChange={() => {
                  setDetails({
                    ...details,
                    name: document.getElementById("name-input").value,
                  });
                }}
              ></input>
              <input
                className="detail-input"
                type="email"
                placeholder="E-MAIL"
                id="mail-input"
                onChange={() => {
                  setDetails({
                    ...details,
                    mail: document.getElementById("mail-input").value,
                  });
                }}
              ></input>
              <input
                className="detail-input"
                placeholder="PHONE NO."
                id="phone-input"
                type="number"
                inputMode="numeric"
                maxLength={15}
                onChange={() => {
                  setDetails({
                    ...details,
                    phone: document.getElementById("phone-input").value,
                  });
                }}
              ></input>
            </div>
            <div className="contact-det-section">
              <textarea
                className="message-input"
                type="text"
                placeholder="MESSAGE"
                id="msg-input"
                onChange={() => {
                  setDetails({
                    ...details,
                    message: document.getElementById("msg-input").value,
                  });
                }}
              ></textarea>
            </div>
          </form>
          <button onClick={SendData} className="submit-button">
            SUBMIT
          </button>
        </div>
      </div>
      <div className="footer-section footer-section2">
        {/* <div className="quick-links">
          <div className="quick-link-head">Quick Links</div>
          <div className="quick-link-body">
            <AnchorLink href="#Home" className="quick-link">
              Home
            </AnchorLink>
            <AnchorLink href="#About" className="quick-link">
              About
            </AnchorLink>
            <AnchorLink href="#Features" className="quick-link">
              Features
            </AnchorLink>
            <AnchorLink href="#Package" className="quick-link">
              Package
            </AnchorLink>
            <AnchorLink href="#Contact" className="quick-link">
              Contact
            </AnchorLink>
          </div>
        </div> */}
        <div className="contact-detail-div">
          <div className="quick-link-head">Reach Out!</div>
          <div className="detail">
            <MdLocationPin className="detail-icon" />
            <div className="detail-text">
              Plot No.21, Kanchi Kamatchi Nagar, Second Street, Madipakkam,
              Kancheepuram, Chennai, Tamil Nadu - 600091
            </div>
          </div>
          <div className="detail">
            <FaPhoneAlt
              style={{
                width: "20px",
                height: "20px",
                paddingLeft: "5px",
                paddingRight: "5px",
              }}
              className="detail-icon"
            />
            <div className="detail-text">+91 75502 54933</div>
          </div>
          <div className="detail">
            <IoIosMail className="detail-icon" />
            <div className="detail-text">mitbots01@gmail.com</div>
          </div>
          <hr className="detail-separator" />
          <div className="social-media-icons">
            <a
              href="https://www.linkedin.com/company/mitbots-official/"
              target="_window"
            >
              <FaLinkedinIn className="sm-icon" />
            </a>
            <a
              href="https://www.instagram.com/mitbots_official?igsh=bDV4bDA4MHVlZ3Zo"
              target="_window"
            >
              <AiFillInstagram className="sm-icon" />
            </a>
          </div>
        </div>
      </div>
      <div className="success-msg-box" id="smb">
        <div className="success-inner-msg-box">
          <img
            src={closeicon}
            alt="close icon"
            className="success-ok"
            onClick={enableScroll}
          />
          <img src={scimg} alt="success icon" className="success-img" />
        </div>
      </div>
    </div>
  );
};

export default Footer;
