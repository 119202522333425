import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ill7 from "../Media/Authentication_Illustration.png";
import ill8 from "../Media/Chatbot_Illustration.png";
import ill4 from "../Media/Content_Upload_Illustration.png";
import ill5 from "../Media/Interactive_Illustration.png";
import ill3 from "../Media/Multimedia_Integration_Illustration.png";
import ill6 from "../Media/Payment_Illustration.png";
import ill1 from "../Media/Responsive_Design_Illustration.png";
import ill9 from "../Media/Scroll_Animation_Illustration.png";
import ill2 from "../Media/Search_Functionality_Illustration.png";
import "../Styles/Features.css";

const Features = () => {
  return (
    <div id="Features-MB" className="features-container">
      <div className="features-heading">Features</div>
      <div className="features-cards-section">
        <div className="features-card">
          <img
            src={ill1}
            alt="Illustration"
            id="fillust"
            className="feature-illustration-image"
          />
          <div className="feature-name">Responsive Design</div>
          <div className="feature-content-text">
            With our responsive design, you can easily improve user experience
            while making sure your website looks great on all devices.
          </div>
        </div>
        <div className="features-card">
          <img
            src={ill2}
            alt="Illustration"
            id="fillust"
            className="feature-illustration-image"
          />
          <div className="feature-name">Search Functionality</div>
          <div className="feature-content-text">
            Our user-friendly search feature makes it easy to navigate through a
            great amount of content and offers a streamlined and effective user
            experience.
          </div>
        </div>
        <div className="features-card">
          <img
            src={ill3}
            alt="Illustration"
            id="fillust"
            className="feature-illustration-image"
          />
          <div className="feature-name">Multimedia Integration</div>
          <div className="feature-content-text">
            Allow users to engage with dynamic content through the integration
            of videos, audios, and images, enhancing their interactive
            experience.
          </div>
        </div>
        <div className="features-card">
          <img
            src={ill4}
            alt="Illustration"
            id="fillust"
            className="feature-illustration-image"
          />
          <div className="feature-name">Content Upload</div>
          <div className="feature-content-text">
            Enable customers to easily upload and manage their content, ensuring
            a smooth and efficient user experience for everyone.
          </div>
        </div>
        <div className="features-card">
          <img
            src={ill5}
            alt="Illustration"
            id="fillust"
            className="feature-illustration-image"
          />
          <div className="feature-name">Interactive Illustrations</div>
          <div className="feature-content-text">
            Transform your narrative into an interactive journey— Capture your
            audience with immersive illustrations.
          </div>
        </div>
        <div className="features-card">
          <img
            src={ill6}
            alt="Illustration"
            id="fillust"
            className="feature-illustration-image"
          />
          <div className="feature-name">Payment Methods</div>
          <div className="feature-content-text">
            Streamline transactions with ease— we offer a variety of payment
            options for a safe and practical user experience.
          </div>
        </div>
        <div className="features-card">
          <img
            src={ill7}
            alt="Illustration"
            id="fillust"
            className="feature-illustration-image"
          />
          <div className="feature-name">Authentication via OTPs</div>
          <div className="feature-content-text">
            Prioritise security with ease— our OTP-based authentication
            guarantees a reliable and easy-to-use platform access control.
          </div>
        </div>
        <div className="features-card">
          <img
            src={ill8}
            alt="Illustration"
            id="fillust"
            className="feature-illustration-image"
          />
          <div className="feature-name">Integrated Chatbot</div>
          <div className="feature-content-text">
            Personalised and instantaneous interactions are provided by our
            integrated chatbot, which makes it easy to increase user engagement
            and improve user experiences.
          </div>
        </div>
        <div className="features-card">
          <img
            src={ill9}
            alt="Illustration"
            id="fillust"
            className="feature-illustration-image"
          />
          <div className="feature-name">Scroll-Driven Animation</div>
          <div className="feature-content-text">
            Engage and captivate users through dynamic storytelling-our
            scroll-driven animation adds a visually compelling dimension to your
            website.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
