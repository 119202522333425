import React from "react";
import "../Styles/About.css";

const About = () => {
  return (
    <div id="About" className="about-container">
      <div className="about-heading">Unveiling Tech Brilliance</div>
      <div className="about-content">
        As a start, we specialize in creating unique online experiences that go
        beyond traditional website design. Our team of experts is committed to
        apply their experience to your needs. Every line of code converges to
        breathe life in your ideas.
      </div>
      <div className="essence-link">
        <div className="know-more-box">
          <div className="know-more-button">
            <div className="know-more-text">Discover our essence</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
