import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import {
  ContactShadows,
  OrbitControls,
  PresentationControls,
} from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import React, { Suspense, useEffect, useRef, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./App.css";
import Model from "./Assets/Mitbot";
import ill7 from "./Media/Authentication_Illustration.png";
import ill8 from "./Media/Chatbot_Illustration.png";
import ill4 from "./Media/Content_Upload_Illustration.png";
import ill5 from "./Media/Interactive_Illustration.png";
import Logo from "./Media/Logo-Transbg.png";
import ill3 from "./Media/Multimedia_Integration_Illustration.png";
import Offer1 from "./Media/offer1.png";
import Offer2 from "./Media/offer2.png";
import Offer3 from "./Media/offer3.png";
import Offer4 from "./Media/offer4.png";
import Offer5 from "./Media/offer5.png";
import Offer6 from "./Media/offer6.png";
import ill6 from "./Media/Payment_Illustration.png";
import ill1 from "./Media/Responsive_Design_Illustration.png";
import ill9 from "./Media/Scroll_Animation_Illustration.png";
import ill2 from "./Media/Search_Functionality_Illustration.png";
import About from "./Pages/About";
import Choose from "./Pages/Choose";
import Footer from "./Pages/Footer";
import Founders from "./Pages/Founders";
import Project from "./Pages/Projects";
import Way from "./Pages/Way";
import "./Styles/About.css";
import "./Styles/Features.css";
import "./Styles/Offers.css";

function Main() {
  const h = window.innerHeight;
  const canvasRef = useRef(null);

  let index = 1;

  //   function rotate_toy(e) {
  //     // var ast = document.querySelector("Model");
  //     var x = e.clientX - window.innerWidth / 2;
  //     var y = e.clientY - window.innerHeight / 2;
  //     var q = 0.15;

  //     x = x * q * 1.25;
  //     y = -y * q * 1.25;

  //     // ast.style.transform = "rotateY(" + x + "deg) rotateX(" + y + "deg)";
  //     Model.rotation.y = x;
  //     Model.rotation.x = y;
  //   }

  //   document.addEventListener("mousemove", rotate_toy);

  const [y, setY] = useState(window.scrollY);
  const [scroll, setScroll] = useState("0%");

  useEffect(() => {
    var flag = "forward";
    var prev = 0;
    var t = window.innerHeight * 15;
    const handleScroll = () => {
      const scrolled = document.getElementById("main").scrollTop;
      console.log(window.innerHeight);

      setY(window.scrollY);
      setScroll(`${((y + scrolled) / t) * 100}%`);

      var scrolledPercentage = ((scrolled * 0.3) / window.innerHeight) * 100;
      console.log("Sc Per: " + scrolledPercentage);

      if (scrolledPercentage > 310) {
        document.getElementById("fdisk").style.transform = "rotate(240deg)";
        document.getElementById("fdisk").style.transition = "2s";

        document.getElementsByClassName("ill1")[0].style.transform =
          "translate(-150%, -150%) rotate(-240deg)";
        document.getElementsByClassName("ill2")[0].style.transform =
          "translate(-180%, 0%) rotate(-240deg)";
        document.getElementsByClassName("ill3")[0].style.transform =
          "translate(-150%, 150%) rotate(-240deg)";
        document.getElementsByClassName("ill4")[0].style.transform =
          "translate(-30%, 250%) rotate(-240deg)";
        document.getElementsByClassName("ill5")[0].style.transform =
          "translate(70%, 250%) rotate(-240deg)";
        document.getElementsByClassName("ill6")[0].style.transform =
          "translate(140%, 150%) rotate(-240deg)";
        document.getElementsByClassName("ill7")[0].style.transform =
          "translate(160%, -120%) rotate(-240deg)";
        document.getElementsByClassName("ill8")[0].style.transform =
          "translate(100%, -230%) rotate(-240deg)";
        document.getElementsByClassName("ill9")[0].style.transform =
          "translate(0%, -260%) rotate(-240deg)";

        let i;
        for (i = 8; i >= 0; i--) {
          document.getElementsByClassName("feature-illustration")[
            i
          ].style.transition = "2s";
        }
        document.getElementById("fh1").innerHTML = "Authentication via OTPs";
        document.getElementById("fb1").innerHTML =
          "Prioritise security with ease— our OTP-based authentication guarantees a reliable and easy-to-use platform access control.";
        document.getElementById("fh2").innerHTML = "Integrated Chatbot";
        document.getElementById("fb2").innerHTML =
          "Personalised and instantaneous interactions are provided by our integrated chatbot, which makes it easy to increase user engagement and improve user experiences.";
        document.getElementById("fh3").innerHTML = "Scroll-Driven Animation";
        document.getElementById("fb3").innerHTML =
          "Engage and captivate users through dynamic storytelling-our scroll-driven animation adds a visually compelling dimension to your website.";
      } else if (scrolledPercentage > 290) {
        document.getElementById("fdisk").style.transform = "rotate(120deg)";
        document.getElementById("fdisk").style.transition = "2s";

        document.getElementsByClassName("ill1")[0].style.transform =
          "translate(-150%, -150%) rotate(-120deg)";
        document.getElementsByClassName("ill2")[0].style.transform =
          "translate(-180%, 0%) rotate(-120deg)";
        document.getElementsByClassName("ill3")[0].style.transform =
          "translate(-150%, 150%) rotate(-120deg)";
        document.getElementsByClassName("ill4")[0].style.transform =
          "translate(-40%, 250%) rotate(-120deg)";
        document.getElementsByClassName("ill5")[0].style.transform =
          "translate(70%, 250%) rotate(-120deg)";
        document.getElementsByClassName("ill6")[0].style.transform =
          "translate(140%, 150%) rotate(-120deg)";
        document.getElementsByClassName("ill7")[0].style.transform =
          "translate(160%, -120%) rotate(-120deg)";
        document.getElementsByClassName("ill8")[0].style.transform =
          "translate(100%, -230%) rotate(-120deg)";
        document.getElementsByClassName("ill9")[0].style.transform =
          "translate(0%, -260%) rotate(-120deg)";

        let i;
        for (i = 0; i < 9; i++) {
          document.getElementsByClassName("feature-illustration")[
            i
          ].style.transition = "2s";
        }

        document.getElementById("fh1").innerHTML = "Content Upload";
        document.getElementById("fb1").innerHTML =
          "Enable customers to easily upload and manage their content, ensuring a smooth and efficient user experience for everyone.";
        document.getElementById("fh2").innerHTML = "Interactive Illustrations";
        document.getElementById("fb2").innerHTML =
          "Transform your narrative into an interactive journey— Capture your audience with immersive illustrations.";
        document.getElementById("fh3").innerHTML = "Payment Methods";
        document.getElementById("fb3").innerHTML =
          "Streamline transactions with ease— we offer a variety of payment options for a safe and practical user experience.";
      } else {
        document.getElementById("fdisk").style.transform = "rotate(0deg)";
        document.getElementById("fdisk").style.transition = "2s";

        document.getElementsByClassName("ill1")[0].style.transform =
          "translate(-150%, -150%) rotate(0deg)";
        document.getElementsByClassName("ill2")[0].style.transform =
          "translate(-180%, 0%) rotate(0deg)";
        document.getElementsByClassName("ill3")[0].style.transform =
          "translate(-150%, 150%) rotate(0deg)";
        document.getElementsByClassName("ill4")[0].style.transform =
          "translate(-30%, 250%) rotate(0deg)";
        document.getElementsByClassName("ill5")[0].style.transform =
          "translate(70%, 250%) rotate(0deg)";
        document.getElementsByClassName("ill6")[0].style.transform =
          "translate(140%, 150%) rotate(0deg)";
        document.getElementsByClassName("ill7")[0].style.transform =
          "translate(160%, -120%) rotate(0deg)";
        document.getElementsByClassName("ill8")[0].style.transform =
          "translate(100%, -230%) rotate(0deg)";
        document.getElementsByClassName("ill9")[0].style.transform =
          "translate(0%, -260%) rotate(0deg)";

        let i;
        for (i = 0; i < 9; i++) {
          document.getElementsByClassName("feature-illustration")[
            i
          ].style.transition = "2s";
        }

        document.getElementById("fh1").innerHTML = "Responsive Design";
        document.getElementById("fb1").innerHTML =
          "With our responsive design, you can easily improve user experience while making sure your website looks great on all devices.";
        document.getElementById("fh2").innerHTML = "Search Functionality";
        document.getElementById("fb2").innerHTML =
          "Our user-friendly search feature makes it easy to navigate through a great amount of content and offers a streamlined and effective user experience";
        document.getElementById("fh3").innerHTML = "Multimedia Integration";
        document.getElementById("fb3").innerHTML =
          "Allow users to engage with dynamic content through the integration of videos, audios, and images, enhancing their interactive experience.";
      }

      if (scrolledPercentage > 90 && scrolledPercentage < 241) {
        const progress = (scrolledPercentage - 90) / 30;
        const translateX = progress * -40;
        document.getElementsByClassName(
          "offer-slider"
        )[0].style.transform = `translateX(${translateX}vw)`;
      }

      if (prev < scrolledPercentage) flag = "forward";
      else flag = "reverse";

      prev = scrolledPercentage;

      if (scrolledPercentage > 30 && flag === "forward") {
        document.getElementsByClassName(
          "home-container"
        )[0].style.backgroundColor = "transparent";
        document.getElementsByClassName("home-container")[0].style.transition =
          "2s";
        document.getElementsByClassName(
          "title-layer-1"
        )[0].style.backgroundImage = "none";
        document.getElementsByClassName("title-layer-1")[0].style.transition =
          "2s";
        document.getElementsByClassName("title-layer-2")[0].style.color =
          "transparent";
        document.getElementsByClassName("title-layer-2")[0].style.transition =
          "2s";
        document.getElementsByClassName("home-caption")[0].style.opacity = 0;
        document.getElementsByClassName("home-caption")[0].style.transition =
          "0.2s";
      }
      if (scrolledPercentage < 42 && flag === "reverse") {
        document.getElementsByClassName(
          "home-container"
        )[0].style.backgroundColor = "#161616";
        document.getElementsByClassName("home-container")[0].style.transition =
          "1s";
        document.getElementsByClassName(
          "title-layer-1"
        )[0].style.backgroundImage =
          "linear-gradient(to bottom, white, grey, black)";
        document.getElementsByClassName(
          "title-layer-1"
        )[0].style.backgroundClip = "text";
        document.getElementsByClassName("title-layer-1")[0].style.transition =
          "3s";
        document.getElementsByClassName("title-layer-2")[0].style.color =
          "rgb(255, 255, 255, 0.24)";
        document.getElementsByClassName("title-layer-2")[0].style.transition =
          "3s";
        document.getElementsByClassName("home-caption")[0].style.opacity = 1;
        document.getElementsByClassName("home-caption")[0].style.transition =
          "4.2s";
      }

      if (scrolledPercentage > 30) {
        scrolledPercentage = 30;
      }

      if (canvasRef.current) {
        canvasRef.current.style.transform = `translateX(${scrolledPercentage}%)`;
      }
    };

    document.getElementById("main").addEventListener("scroll", handleScroll);

    const interval = setInterval(() => {
      // let cid = caption_words[index];
      // document.getElementById(cid).className = "past-caption-word";
      var val = index * 52;
      index++;
      document.getElementById("scroll-caption").style.transform =
        "translateY(-" + val + "px)";
      document.getElementById("scroll-caption").style.transition = "1s";
      if (index > 4) {
        index = 0;
      }
      // cid = caption_words[index];
      // document.getElementById(cid).className = "current-caption-word";
    }, 3600);

    return () => {
      clearInterval(interval);
      document
        .getElementById("main")
        .removeEventListener("scroll", handleScroll);
    };
  }, [h]);

  return (
    <>
      <div className="progressbar" style={{ width: scroll }}></div>
      <Parallax pages={16} id="main">
        {/* About Layer */}
        <ParallaxLayer
          offset={0}
          speed={0.5}
          sticky={{ start: 0, end: 2 }}
          style={{ zIndex: "-12" }}
          className="abt-parallax"
        >
          <About />
        </ParallaxLayer>

        {/* Home Layer */}
        <ParallaxLayer
          offset={0}
          speed={0.5}
          sticky={{ start: 0, end: 1 }}
          style={{ zIndex: "-11" }}
        >
          <div className="home-container">
            <header className="logo-holder">
              <img src={Logo} alt="Mitbots Logo" className="logo" />
            </header>
            <div className="home-body">
              <div className="home-caption">
                Embrace Brilliance of{" "}
                <span id="caption-word-change">
                  <span id="scroll-caption" className="home-caption-word">
                    <span className="current-caption-word" id="Technology">
                      Technology
                    </span>
                    <span className="past-caption-word" id="Digitalization">
                      Digitalization
                    </span>
                    <span className="past-caption-word" id="Design">
                      Design
                    </span>
                    <span className="past-caption-word" id="Screens">
                      Screens
                    </span>
                    <span className="past-caption-word" id="Creativity">
                      Creativity
                    </span>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </ParallaxLayer>

        {/* title layer 1 */}
        <ParallaxLayer
          offset={0}
          speed={0.5}
          sticky={{ start: 0, end: 0 }}
          style={{ zIndex: "-10" }}
        >
          <div className="title-layer-1">MITBOTS</div>
        </ParallaxLayer>

        {/* Mitbot Layer */}
        <ParallaxLayer
          offset={0}
          speed={0.5}
          sticky={{ start: 0, end: 2 }}
          style={{ zIndex: "-9" }}
        >
          <div
            style={{
              width: "90vw",
              height: "90vh",
              margin: "auto",
              paddingTop: "5vh",
            }}
            ref={canvasRef}
          >
            <Canvas className="canva" id="bot-canva" shadows={0.5}>
              <ambientLight intensity={2.4} />
              <directionalLight position={[0, 10, 5]} intensity={1} />
              <OrbitControls enableZoom={false} />
              <Suspense fallback={null}>
                <Model />
              </Suspense>
              <ContactShadows
                position={[0, -2, 0]}
                opacity={0.5}
                scale={12}
                blur={1.2}
                far={12}
                resolution={256}
                color={"black"}
              />
            </Canvas>
          </div>
        </ParallaxLayer>

        {/* title layer 2 */}
        <ParallaxLayer offset={0} speed={0.5} sticky={{ start: 0, end: 0 }}>
          <div className="title-layer-2">MITBOTS</div>
        </ParallaxLayer>

        {/* Offers Layers start here*/}
        <ParallaxLayer offset={3} speed={0.5} sticky={{ start: 3, end: 8 }}>
          <div className="offers-heading">What we offer</div>
        </ParallaxLayer>

        <ParallaxLayer
          offset={3}
          speed={0.5}
          sticky={{ start: 3, end: 8 }}
          className="offer-mockup"
        >
          <div className="offer-box">
            <div className="offer-slider">
              <img className="offer-pic" alt="Portfolios" src={Offer1} />
              <img className="offer-pic" alt="Portfolios" src={Offer2} />
              <img className="offer-pic" alt="Portfolios" src={Offer3} />
              <img className="offer-pic" alt="Portfolios" src={Offer4} />
              <img className="offer-pic" alt="Portfolios" src={Offer5} />
              <img className="offer-pic" alt="Portfolios" src={Offer6} />
            </div>
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={3} speed={0.5} className="offer">
          <div className="offers-head">Design of Websites & Apps</div>
          <div className="offers-content">
            Impress your viewers with aesthetically beautiful apps and webpages.
            Our designs create appealing digital interfaces that have a lasting
            impression by effectively combining functionality and aesthetics.
          </div>
        </ParallaxLayer>
        <ParallaxLayer offset={4} speed={0.5} className="offer">
          <div className="offers-head">Portfolio Development</div>
          <div className="offers-content">
            Stand out professionally with our customised portfolio designs,
            which are made to showcase your unique talents and make a lasting
            impression.
          </div>
        </ParallaxLayer>
        <ParallaxLayer offset={5} speed={0.5} className="offer">
          <div className="offers-head">Business and Product Websites</div>
          <div className="offers-content">
            With our custom websites for businesses and products, ignite online
            success. We create captivating digital experiences that captivate
            users and propel business growth.
          </div>
        </ParallaxLayer>
        <ParallaxLayer offset={6} speed={0.5} className="offer">
          <div className="offers-head">E-Commerce Sites</div>
          <div className="offers-content">
            With the help of our robust e-commerce platforms, unleash the
            potential of online sales. We design smooth, intuitive platforms
            that draw users in and ensures a delightful and safe buying
            experience.
          </div>
        </ParallaxLayer>
        <ParallaxLayer offset={7} speed={0.5} className="offer">
          <div className="offers-head">Domain Hosting</div>
          <div className="offers-content">
            Our robust domain hosting services make it easy to build a strong
            online presence. You can rely on us to offer the framework for a
            compelling, dependable, and easily navigable website.
          </div>
        </ParallaxLayer>
        <ParallaxLayer offset={8} speed={0.5} className="offer">
          <div className="offers-head">Application Development</div>
          <div className="offers-content">
            With the help of our creative app development services, implement
            your ideas. We are experts at creating user-friendly mobile
            applications that fascinate and involve a diverse range of clients,
            from conception to implementation.
          </div>
        </ParallaxLayer>

        {/* Offers layers ends here */}

        {/* Features Layer */}
        <ParallaxLayer offset={9} speed={0.5} sticky={{ start: 9, end: 11 }}>
          <div id="Features-MB" className="Features-container">
            <div className="features-heading">Features</div>
            <div className="features-sections">
              <div className="features-section">
                <div className="feature">
                  <div className="feature-head" id="fh1">
                    Responsive Design
                  </div>
                  <div className="feature-body" id="fb1">
                    With our responsive design, you can easily improve user
                    experience while making sure your website looks great on all
                    devices.
                  </div>
                </div>
                <div className="feature-separator">
                  <span className="feature-separator-line" />
                  <span className="feature-separator-dot"></span>
                </div>
                <div className="feature">
                  <div className="feature-head" id="fh2">
                    Search Functionality
                  </div>
                  <div className="feature-body" id="fb2">
                    Our user-friendly search feature makes it easy to navigate
                    through a great amount of content and offers a streamlined
                    and effective user experience.
                  </div>
                </div>
                <div className="feature-separator">
                  <span className="feature-separator-line" />
                  <span className="feature-separator-dot"></span>
                </div>
                <div className="feature">
                  <div className="feature-head" id="fh3">
                    Multimedia Integration
                  </div>
                  <div className="feature-body" id="fb3">
                    Allow users to engage with dynamic content through the
                    integration of videos, audios, and images, enhancing their
                    interactive experience.
                  </div>
                </div>
              </div>
              <div className="features-section">
                <div id="fdisk" className="disk">
                  <img
                    src={ill1}
                    alt="Illustration"
                    id="fillust"
                    className="feature-illustration ill1"
                  />
                  <img
                    src={ill2}
                    alt="Illustration"
                    id="fillust"
                    className="feature-illustration ill2"
                  />
                  <img
                    src={ill3}
                    alt="Illustration"
                    id="fillust"
                    className="feature-illustration ill3"
                  />
                  <img
                    src={ill4}
                    alt="Illustration"
                    id="fillust"
                    className="feature-illustration ill4"
                  />
                  <img
                    src={ill5}
                    alt="Illustration"
                    id="fillust"
                    className="feature-illustration ill5"
                  />
                  <img
                    src={ill6}
                    alt="Illustration"
                    id="fillust"
                    className="feature-illustration ill6"
                  />
                  <img
                    src={ill7}
                    alt="Illustration"
                    id="fillust"
                    className="feature-illustration ill7"
                  />
                  <img
                    src={ill8}
                    alt="Illustration"
                    id="fillust"
                    className="feature-illustration ill8"
                  />
                  <img
                    src={ill9}
                    alt="Illustration"
                    id="fillust"
                    className="feature-illustration ill9"
                  />
                  <div className="inner-disk">
                    <div className="disk-hole"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ParallaxLayer>

        {/* Project Layer */}
        <ParallaxLayer offset={12} speed={0.5}>
          <Project />
        </ParallaxLayer>

        {/* Founders Layer */}
        <ParallaxLayer offset={12.8} speed={0.5}>
          <Founders />
        </ParallaxLayer>

        {/* Why Choose Us Layer */}
        <ParallaxLayer offset={13.1} speed={0.5}>
          <Choose />
        </ParallaxLayer>

        {/* How to place order */}
        <ParallaxLayer
          offset={14.1}
          speed={0.5}
          sticky={{ start: 14.1, end: 15 }}
        >
          <Way />
        </ParallaxLayer>

        {/* How to - Mitbot */}
        <ParallaxLayer
          offset={14}
          speed={0.5}
          sticky={{ start: 14, end: 15 }}
          style={{ zIndex: "" }}
        >
          <div
            style={{
              width: "90vw",
              height: "90vh",
              margin: "auto",
              paddingTop: "20vh",
              paddingLeft: "25px",
            }}
          >
            <Canvas
              className="canva"
              id="bot-canva"
              shadows={0.5}
              camera={{ position: [0, 0, 5] }}
              onPointerLeave={() => {
                const canvas = document.getElementById("bot-canva");
                if (canvas) {
                  canvas.dispatchEvent(new Event("pointerleave"));
                }
              }}
            >
              <ambientLight intensity={2.4} />
              <directionalLight position={[0, 10, 5]} intensity={1} />
              <PresentationControls cursor={true} />
              <Suspense fallback={null}>
                <Model />
              </Suspense>
              <ContactShadows
                position={[0, -2, 0]}
                opacity={0.5}
                scale={12}
                blur={1.2}
                far={12}
                resolution={256}
                color={"black"}
              />
            </Canvas>
          </div>
        </ParallaxLayer>

        {/* Footer Layer */}
        <ParallaxLayer
          offset={9}
          speed={0.5}
          sticky={{ start: 15, end: 15 }}
          style={{
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <Footer />
        </ParallaxLayer>
      </Parallax>
    </>
  );
}

export default Main;
