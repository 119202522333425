import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./App.css";
import About from "./Pages/About";
import Choose from "./Pages/Choose";
import Features from "./Pages/Features";
import Footer from "./Pages/Footer";
import Founders from "./Pages/Founders";
import Home from "./Pages/Home";
import Offer from "./Pages/Offers2";
import Project from "./Pages/Projects";
import Way from "./Pages/Way";
import "./Styles/About.css";
import "./Styles/Features.css";
import "./Styles/Offers.css";

function Portrait() {
  return (
    <>
      <Home />
      <About />
      <Offer />
      <Features />
      <Project />
      <Founders />
      <Choose />
      <Way />
      <Footer />
    </>
  );
}

export default Portrait;
