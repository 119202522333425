/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.0 Mitbot.gltf 
*/

import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { easing } from "maath";
import React, { useRef, useState } from "react";
import * as THREE from "three";

export default function Model(props) {
  const { nodes, materials } = useGLTF("/Mitbot.gltf");

  const mitbot = useRef();
  const [dummy] = useState(() => new THREE.Object3D());
  const originalPosition = useRef(new THREE.Vector3(0, 0, 0));
  useFrame((state, dt) => {
    dummy.lookAt(state.pointer.x, state.pointer.y, 1);
    easing.dampQ(mitbot.current.quaternion, dummy.quaternion, 0.15, dt);
  });

  const handlePointerLeave = () => {
    mitbot.current.position.copy(originalPosition.current);
  };

  React.useEffect(() => {
    if (mitbot.current) {
      originalPosition.current.copy(mitbot.current.position);
    }
  }, []);

  return (
    <group
      ref={mitbot}
      {...props}
      dispose={null}
      scale={2}
      rotation={[0.1, 0, 0]}
      onPointerLeave={handlePointerLeave}
    >
      <mesh
        geometry={nodes.Mesh_0.geometry}
        material={materials["Material.011"]}
      />
      <mesh
        geometry={nodes.Cylinder.geometry}
        material={materials["Material.001"]}
        position={[-0.368, 0.491, 0.013]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={0.176}
      />
      <mesh
        geometry={nodes.Cylinder001.geometry}
        material={materials["Material.002"]}
        position={[0.365, 0.491, 0.013]}
        rotation={[0, 0, Math.PI / 2]}
        scale={0.176}
      />
      <mesh
        geometry={nodes.Torus.geometry}
        material={materials["Material.005"]}
        position={[-0.532, -0.393, 0.037]}
        rotation={[0, 0, -0.159]}
        scale={[0.087, 0.142, 0.111]}
      />
      <mesh
        geometry={nodes.Torus001.geometry}
        material={materials["Material.005"]}
        position={[0.535, -0.393, 0.037]}
        rotation={[0, 0, 0.141]}
        scale={[0.087, 0.142, 0.111]}
      />
      <mesh
        geometry={nodes.Icosphere.geometry}
        material={materials["Material.005"]}
        position={[-0.568, -0.184, 0.031]}
        rotation={[0, 0, -0.164]}
        scale={[0.056, 0.108, 0.09]}
      />
      <mesh
        geometry={nodes.Icosphere001.geometry}
        material={materials["Material.005"]}
        position={[0.566, -0.184, 0.031]}
        rotation={[0, 0, 0.164]}
        scale={[0.056, 0.108, 0.09]}
      />
      <mesh
        geometry={nodes.Torus002.geometry}
        material={materials["Material.005"]}
        position={[0.232, -0.975, 0.085]}
        scale={[0.131, 0.197, 0.154]}
      />
      <mesh
        geometry={nodes.Torus003.geometry}
        material={materials["Material.005"]}
        position={[-0.23, -0.975, 0.085]}
        scale={[0.131, 0.197, 0.154]}
      />
      <mesh
        geometry={nodes.Torus005.geometry}
        material={materials["Material.005"]}
        position={[0.205, -0.745, 0.052]}
        rotation={[0.064, 0, 0.041]}
        scale={[0.166, 0.21, 0.182]}
      />
      <mesh
        geometry={nodes.Torus004.geometry}
        material={materials["Material.005"]}
        position={[-0.207, -0.745, 0.046]}
        rotation={[0.064, -0.005, -0.04]}
        scale={[0.161, 0.204, 0.177]}
      />
      <mesh
        geometry={nodes.Cylinder002.geometry}
        material={materials["Material.005"]}
        position={[-0.001, -0.245, 0.285]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.122}
      />
      <mesh
        geometry={nodes.Cylinder004.geometry}
        material={materials["Material.002"]}
        position={[0.188, -0.276, 0.261]}
        rotation={[1.691, 0.346, -0.544]}
        scale={0.091}
      />
      <mesh
        geometry={nodes.Cylinder003.geometry}
        material={materials["Material.001"]}
        position={[-0.186, -0.276, 0.265]}
        rotation={[1.315, 0.264, 0.585]}
        scale={0.091}
      />
      <mesh
        geometry={nodes.Cube.geometry}
        material={materials["Material.005"]}
        position={[0, -0.027, 0.325]}
        rotation={[-0.102, 0, 0]}
        scale={[0.178, 0.084, 0.073]}
      />
      <mesh
        geometry={nodes.Icosphere002.geometry}
        material={materials["Material.012"]}
        position={[0.001, 0.51, 0.432]}
        scale={[0.437, 0.36, 0.361]}
      />
    </group>
  );
}

useGLTF.preload("/Mitbot.gltf");
