import React from "react";
import ReactDOM from "react-dom/client";
import { Helmet } from "react-helmet";
import App from "./App";
import "./index.css";
import Logo from "./Media/Logo-Transbg.png";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Helmet>
      <title>Innocom</title>
      <meta property="og:site_name" content="MITBOTS"></meta>
      <meta property="og:title" content="MITBOTS" />
      <meta
        property="og:description"
        content="Embrace the brilliance of technology"
      />
      <meta property="og:image" content={Logo} />
      {/* <meta property="og:url" content="https://innocom.tech" /> */}
      <meta property="og:type" content="website" />
    </Helmet>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
