import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "./App.css";
import Main from "./Main";
import Cursor from "./Pages/Cursor";
import Portrait from "./Portrait";

function App() {
  const [isPortrait, setIsPortrait] = useState(false);
  const [isCursorPresent, setIsCursorPresent] = useState(false);

  const handleResize = () => {
    setIsPortrait(window.innerWidth < 1024);
  };

  const handleMouseMove = () => {
    setIsCursorPresent(true);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);
  return (
    <div className="App">
      <Helmet>
        <title>Mitbots</title>
      </Helmet>
      {isCursorPresent && !isPortrait ? <Cursor /> : null}
      {isPortrait ? <Portrait /> : <Main />}
    </div>
  );
}

export default App;
